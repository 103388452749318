.contentCard {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  padding: 16px;
}

.contentCardMetricLink {
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-rows: 1fr;
  gap: 0;
  align-items: center;
}

.contentCardTitle {
  color: var(--medium);
  font-size: 1rem;
  line-height: 1;
}

.contentCardValue {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: 8px;
}

.contentCardIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contentCardLabel,
.contentCardLabelLink,
.contentCardDuration {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
}

.contentCardLabel .contentCardValue,
.contentCardLabelLink .contentCardValue {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  margin: 0px;
}

.contentCardLabelLink {
  color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  font-size: 0.95rem;
  text-align: right;
}

.contentCardLabelLink:hover {
  color: var(--blue);
}