.analytics {
  max-width: var(--max-width);
  min-height: calc(100svh - 130px);
  margin-inline: auto;
  padding: 32px 16px;
}

.analyticsHead {
  border-bottom: 0.5px solid #22222210;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 32px;
  padding-bottom: 16px;
}

.analyticsFilters {
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-rows: 1fr;
  gap: 8px;
  width: 100%;
  max-width: 300px;
}

.analyticsConfig {
  background-color: #5a728208;
  border: var(--border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.analyticsHeadTitle {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.analyticsChartTitle {
  color: var(--black);
  font-size: 1.44rem;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.25px;
  margin-bottom: 16px;
  padding-right: 52px;
}

.analyticsWrapper {
  border-bottom: 0.5px solid #22222210;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.analyticsWrapper:last-child {
  border: none;
}

.analyticsPanel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.analyticsPanelCard {
  background-color: #5a728208;
  border: var(--border);
  border-radius: 4px;
  color: #5a7282;
  padding: 16px;
}

.analyticsChart {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  width: 100%;
  min-height: calc(360px + 16px + 1.44rem);
  padding: 24px;
}

@media screen and (min-width: 968px) {
  .analyticsWrapper {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-rows: 1fr;
    gap: 32px;
    width: 100%;
  }
}