.proposal {
  max-width: var(--max-width);
  min-height: calc(100svh - 130px);
  margin-inline: auto;
  padding: 32px 16px;
}

.proposalPanelSection {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
}

.proposalBody {
  overflow-x: auto;
}

.resultsChart {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: calc(360px + 16px + 1.44rem);
  margin-bottom: 24px;
  padding: 24px;
}

.resultsChart ul {
  margin-top: 16px;
}

.resultsChart ul li {
  color: var(--medium);
  font-size: 0.85rem;
  margin-left: 16px;
  line-height: 1.2;
  margin-bottom: 8px;
}

.resultsChartTitle {
  color: var(--black);
  font-size: 1.44rem;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.25px;
  margin-bottom: 16px;
  padding-right: 52px;
  text-transform: capitalize;
}

.proposalFlagged {
  border: 1px solid rgba(255, 0, 0, 0.25);
  background-color: rgba(255, 0, 0, 0.05);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 1fr;
  gap: 8px;
  padding: 16px;
  margin-bottom: 32px;
}

/* Progress */
.chartLoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.chartLoader {
  width: 120px;
  height: 20px;
  border-radius: 20px;
  background-image:
    repeating-linear-gradient(135deg, #141a99 0 10px, #141a9950 0 20px),
    repeating-linear-gradient(135deg, #ddd 0 10px, #eee 0 20px);
  background-size:
    0% 100%,
    100% 100%;
  background-repeat:
    no-repeat,
    no-repeat;
  background-position:
    left top,
    left top;
}

/* Markdown */
.markdown {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  max-width: 100%;
  color: var(--black);
  padding: 24px;
  margin-bottom: 40px;
}

.markdown h1 {
  display: none;
  line-height: 40px;
  font-size: 32px;
  margin: 40px 0 16px;
}

.markdown h2 {
  margin: 0 0 16px 0;
  line-height: 32px;
  font-size: 24px;
}

.markdown h3 {
  margin: 28px 0 16px;
  line-height: 28px;
  font-size: 20px;
}

.markdown h4,
.markdown h5 {
  margin: 24px 0 16px;
  line-height: 24px;
  font-size: 18px;
}

.markdown a {
  color: var(--dark-blue);
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: var(--blue);
}

.markdown a:hover {
  color: var(--blue);
  text-decoration-color: var(--blue);
}

.markdown a,
.markdown p,
.markdown li,
.markdown span,
.markdown blockquote,
.markdown pre,
.markdown code,
.markdown strong,
.markdown table td,
.markdown table th {
  font-size: 18px;
  line-height: 1.6;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.markdown pre,
.markdown table {
  overflow-x: scroll;
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown code {
  margin-bottom: 18px;
}

.markdown li {
  margin-left: 18px;
  padding-left: 18px;
}

.markdown li::marker,
.markdown li::marker {
  font-weight: 600;
  font-size: 18px;
}

.markdown code {
  border-radius: 4px;
  background-color: #E8F1FC;
  display: inline-block;
  padding: 4px 8px;
}

.markdown table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 32px;
}

.markdown table th {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--black);
  font-weight: 500;
  padding: 8px;
}

.markdown table td {
  padding: 8px;
}

.markdown table tr {
  border-bottom: 1px solid #22222220;
}

.markdown img {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
}

@media screen and (min-width: 968px) {
  .proposal {
    display: grid;
    grid-template-columns: minmax(360px, 1fr) 300px;
    grid-template-rows: 1fr;
    gap: 80px;
  }
}