.profileCard {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  max-width: 320px;
  padding: 16px;
}

.profileCardImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.profileCard img {
  border: var(--border);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profileCardPlaceholder {
  background: #BCDBFC;
  border-radius: 8px;
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.profileCardHead {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  line-height: 24px;
  margin: 16px 0;
}

.profileCardHeadIcon {
  background-color: #22222205;
  border-radius: 50%;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
}

.profileCardName {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileCardBody {
  color: var(--black);
  font-size: 0.95rem;
}