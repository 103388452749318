.timelineCard {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.timelineCardTitle {
  color: var(--black);
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 16px;
}

.timelineCardItem {
  display: grid;
  grid-template-columns: 22px 1fr;
  grid-template-rows: 1fr;
  gap: 12px;
  align-items: flex-start;
}

.timelineCardIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timelineCardIcon div:first-child {
  background-color: transparent;
  border: 1px solid #5a728240;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  min-height: 22px;
}

.timelineCardIcon[data-icon="fill"] div:first-child {
  border: 1px solid var(--black);
}

.timelineCardIcon[data-icon="fill"] span {
  background-color: var(--black);
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.timelineCardIcon div:last-child {
  background-color: #5a728240;
  min-width: 1px;
  height: 34px;
}

.timelineCardIcon[data-icon="fill"] div:last-child {
  background-color: var(--black);
}

.timelineCardItem:last-child .timelineCardIcon div:last-child {
  height: 0px;
}

.timelineCardInfo div:first-child {
  color: #60606080;
  font-size: 0.9rem;
  line-height: 22px;
}

.timelineCardInfo div:last-child {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}