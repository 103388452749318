.proposalCard {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
}

.proposalCard h2 {
  font-size: clamp(1.6rem, 2svw, 2rem);
  margin-bottom: 12px;
}

.proposalCardDetails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 1rem;
}

.proposalCardDetails span {
  color: var(--medium);
}

.proposalCardLink {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: nowrap;
}

.proposalCardLink span {
  color: var(--dark-blue);
  font-size: 1rem;
  max-width: 180px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.proposalCardLink:hover span {
  color: var(--blue);
}