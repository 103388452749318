.barChart {
  max-width: initial;
  position: relative;
}

.barChart svg {
  max-width: 100%;
}

.barChartTooltip {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 12px;
  width: auto;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.barChartTooltip div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.barChartTooltip span {
  color: var(--medium);
  display: grid;
  grid-template-columns: 78px 1fr;
  grid-template-rows: 1fr;
  gap: 8px;
  font-weight: 500;
  min-width: 280px;
  max-width: 360px;
  line-height: 20px;
}


.barChartTooltip strong {
  color: var(--black);
  font-weight: 600;
}