.goToBtn {
  background-color: var(--white);
  border: 1px solid var(--dark-blue);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 16px;
  padding: 8px 12px;
  white-space: nowrap;
}

.goToBtn span {
  display: none;
  color: var(--dark-blue);
  font-weight: 500;
}

.goToBtnIcon svg:first-child {
  display: none;
}

.goToBtnIcon svg:last-child {
  display: inline-block;
}

.goToBtn div {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 968px) {
  .goToBtn span {
    display: inline-block;
  }

  .goToBtnIcon svg:first-child {
    display: inline-block;
  }

  .goToBtnIcon svg:last-child {
    display: none;
  }
}