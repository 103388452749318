.dropdown {
  background-color: #5a728208;
  border: var(--border);
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
  position: relative;
}

.dropdownSelected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.dropdownSelected span {
  color: var(--dark);
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
}

.dropdownOptionsWrapper {
  visibility: hidden;
  position: absolute;
  top: 100%;
  right: 0px;
  width: 100%;
  z-index: 1;
  padding-top: 4px;
}

.dropdown:focus-within .dropdownOptionsWrapper {
  visibility: visible;
}

.dropdown:focus-within .dropdownSelected svg {
  transform: rotate(-90deg);
}

.dropdownOptions {
  background-color: var(--white);
  border: var(--border);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 4px;
}

.dropdownOption {
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  padding: 4px 8px;
  text-wrap: nowrap;
}

.dropdownOption:hover {
  background-color: var(--light-blue);
}