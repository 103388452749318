.bubbleChart {
  max-width: initial;
  position: relative;
}

.bubbleChart svg {
  max-width: 100%;
}

.bubbleChartLegend {
  display: flex;
  gap: 8px;
}

.bubbleChartLegendItem {
  display: flex;
  align-items: center;
  gap: 4px;
}

.bubbleChartLegendItem div:last-child {
  color: var(--medium);
  font-size: 12px;
}

.bubbleChartLegendItem div:first-child {
  background-color: #D0D4DC60;
  border: 1px solid #D0D4DC;
  border-radius: 50%;
}

.bubbleChartLegendItem:nth-child(3) div:first-child {
  width: 8px;
  min-height: 8px;
}

.bubbleChartLegendItem:nth-child(2) div:first-child {
  width: 16px;
  min-height: 16px;
}

.bubbleChartLegendItem:nth-child(1) div:first-child {
  width: 32px;
  min-height: 32px;
}

.bubbleChartTooltip {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 12px;
  width: auto;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bubbleChart div,
.bubbleChart span {
  font-size: 16px;
  color: var(--black);
  font-weight: 300;
}

.bubbleChart hr {
  border: none;
  border-top: 0.5px solid #5a728240;
  margin: 4px 0;
}

.bubbleChartTooltip div div {
  display: grid;
  grid-template-columns: 24px 72px;
  grid-template-rows: 1fr;
  gap: 8px;
  align-items: center;
  text-align: right;
}

.bubbleChartTooltip div div:first-child {
  font-size: 16px;
  font-weight: 500;
}

.bubbleChartTooltip div div:last-child {
  color: #5a7282;
  font-size: 12px;
  font-weight: 300;
}

.bubbleChartTooltip div div:last-child div:last-child {
  font-size: 12px;
  color: var(--black);
}