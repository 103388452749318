.donutChartContainer {
  width: 100%;
}

.donutChart {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: initial;
  position: relative;
}

.donutChart svg {
  max-width: 100%;
}

.donutChartLegend {
  display: none;
}

.donutChartLegendItem {
  display: grid;
  grid-template-columns: 14px 1fr 48px;
  grid-template-rows: 1fr;
  gap: 8px;
}

.donutChartLegendLabel {
  max-width: 200px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.donutChartLegendColor {
  border-radius: 50%;
  min-height: 14px;
  min-width: 14px;
}

.donutChartTooltip {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 12px;
  width: auto;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.donutChartTooltip div {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: repeat(3, auto);
  line-height: 20px;
}

.donutChartTooltip span:first-child {
  color: var(--medium);
  font-weight: 500;
}

.donutChartTooltip span:last-child {
  color: var(--black);
  font-weight: 600;
  text-wrap: nowrap;
}

@media screen and (min-width: 968px) {
  .donutChartContainer {
    display: grid;
    grid-template-columns: 1fr 278px;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .donutChartLegend {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    line-height: 12px;
  }
}