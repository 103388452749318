.wrapperChartContainer {
  width: 100%;
  position: relative;
}

.wrapperChart {
  width: 100%;
}

.wrapperChartBtns {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 6px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
}

.wrapperChartBtns button {
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  position: relative;
}

.wrapperChartBtns button:first-child:hover svg,
.wrapperChartBtns button:last-child:hover svg {
  stroke: var(--blue);
}

.wrapperChartBtns button:first-child:hover::before,
.wrapperChartBtns button:last-child:hover::before {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(calc(-100% + 32px), calc(100% + 8px));
  background-color: var(--black);
  border-radius: 4px;
  color: var(--white);
  width: 120px;
  font-size: 14px;
  font-weight: 400;
  text-wrap: wrap;
  text-align: center;
  padding: 6px 12px;
  z-index: 2;
}

.wrapperChartBtns button:last-child:hover::before {
  content: "Download chart";
}

.wrapperChartBtns button:first-child:hover::before {
  content: "Copy link";
}

.wrapperChartBtns button:first-child svg {
  transform: rotate(-45deg);
}

.wrapperChartBtns span {
  background-color: var(--light);
  min-width: 1px;
  min-height: 28px;
}

.wrapperChartLogo {
  position: absolute;
  opacity: 0.1;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  height: 100%;
  z-index: 0;
  user-select: none;
}

.wrapperChartLogo svg path {
  fill: var(--medium);
}

.wrapperChartLogo div {
  color: var(--medium);
  letter-spacing: -0.5px;
  font-size: 30px;
  font-weight: 700;
}